export const ACTIONS = {
  GET_PRODUCT_VERSIONS: 'VERSION/GET_PRODUCT_VERSIONS',
  GET_PRODUCT_VERSIONS_SUCCESS: 'VERSION/GET_PRODUCT_VERSIONS_SUCCESS',
  GET_PRODUCT_VERSIONS_FAILURE: 'VERSION/GET_PRODUCT_VERSIONS_FAILURE',

  POST_PRODUCT_VERSION: 'VERSION/POST_PRODUCT_VERSION',
  POST_PRODUCT_VERSION_SUCCESS: 'VERSION/POST_PRODUCT_VERSION_SUCCESS',
  POST_PRODUCT_VERSION_FAILURE: 'VERSION/POST_PRODUCT_VERSION_FAILURE',

  PATCH_PRODUCT_VERSION: 'VERSION/PATCH_PRODUCT_VERSION',
  PATCH_PRODUCT_VERSION_SUCCESS: 'VERSION/PATCH_PRODUCT_VERSION_SUCCESS',
  PATCH_PRODUCT_VERSION_FAILURE: 'VERSION/PATCH_PRODUCT_VERSION_FAILURE',

  DELETE_PRODUCT_VERSION: 'VERSION/DELETE_PRODUCT_VERSION',
  DELETE_PRODUCT_VERSION_SUCCESS: 'VERSION/DELETE_PRODUCT_VERSION_SUCCESS',
  DELETE_PRODUCT_VERSION_FAILURE: 'VERSION/DELETE_PRODUCT_VERSION_FAILURE',
};
