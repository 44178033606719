export const ACTIONS = {
  GET_PRODUCTS: 'PRODUCTS/GET_PRODUCTS',
  GET_PRODUCTS_SUCCESS: 'PRODUCTS/GET_PRODUCTS_SUCCESS',
  GET_PRODUCTS_FAILURE: 'PRODUCTS/GET_PRODUCTS_FAILURE',

  GET_PRODUCT: 'PRODUCTS/GET_PRODUCT',
  GET_PRODUCT_SUCCESS: 'PRODUCTS/GET_PRODUCT_SUCCESS',
  GET_PRODUCT_FAILURE: 'PRODUCTS/GET_PRODUCT_FAILURE',

  REMOVE_PRODUCT_FROM_MAP: 'PRODUCTS/REMOVE_PRODUCT_FROM_MAP',

  CREATE_PRODUCT: 'PRODUCTS/CREATE_PRODUCT',
  CREATE_PRODUCT_SUCCESS: 'PRODUCTS/CREATE_PRODUCT_SUCCESS',
  CREATE_PRODUCT_FAILURE: 'PRODUCTS/CREATE_PRODUCT_FAILURE',

  EDIT_PRODUCT: 'PRODUCTS/EDIT_PRODUCT',
  EDIT_PRODUCT_SUCCESS: 'PRODUCTS/EDIT_PRODUCT_SUCCESS',
  EDIT_PRODUCT_FAILURE: 'PRODUCTS/EDIT_PRODUCT_FAILURE',

  DELETE_PRODUCT: 'PRODUCTS/DELETE_PRODUCT',
  DELETE_PRODUCT_SUCCESS: 'PRODUCTS/DELETE_PRODUCT_SUCCESS',
  DELETE_PRODUCT_FAILURE: 'PRODUCTS/DELETE_PRODUCT_FAILURE',

  SET_DISPLAY_MODE: 'PRODUCTS/SET_DISPLAY_MODE',

  SET_DISPLAY_BANNER_FOLLOW_PRODUCT: 'PRODUCTS/SET_DISPLAY_BANNER_FOLLOW_PRODUCT',

  GET_ALL_PRODUCTS_COUNT: 'PRODUCTS/GET_ALL_PRODUCTS_COUNT',
  GET_ALL_PRODUCTS_COUNT_SUCCESS: 'PRODUCTS/GET_ALL_PRODUCTS_COUNT_SUCCESS',
  GET_ALL_PRODUCTS_COUNT_FAILURE: 'PRODUCTS/GET_ALL_PRODUCTS_COUNT_FAILURE',

  GET_ALL_MAINTAINED_PRODUCTS_COUNT: 'PRODUCTS/GET_ALL_MAINTAINED_PRODUCTS_COUNT',
  GET_ALL_MAINTAINED_PRODUCTS_COUNT_SUCCESS: 'PRODUCTS/GET_ALL_MAINTAINED_PRODUCTS_COUNT_SUCCESS',
  GET_ALL_MAINTAINED_PRODUCTS_COUNT_FAILURE: 'PRODUCTS/GET_ALL_MAINTAINED_PRODUCTS_COUNT_FAILURE',

  GET_LATEST_PRODUCTS: 'PRODUCTS/GET_LATEST_PRODUCTS',
  GET_LATEST_PRODUCTS_SUCCESS: 'PRODUCTS/GET_LATEST_PRODUCTS_SUCCESS',
  GET_LATEST_PRODUCTS_FAILURE: 'PRODUCTS/GET_LATEST_PRODUCTS_FAILURE',

  GET_PARENT_PRODUCT: 'PRODUCTS/GET_PARENT_PRODUCT',
  GET_PARENT_PRODUCT_SUCCESS: 'PRODUCTS/GET_PARENT_PRODUCT_SUCCESS',
  GET_PARENT_PRODUCT_FAILURE: 'PRODUCTS/GET_PARENT_PRODUCT_FAILURE',

};

export const validationStatuses = {
  RDLV: 'REQUIRE DOMAIN LEADER VALIDATION',
  RCA: 'REQUIRE COMMUNITY ARCHITECT',
  RBUA: 'REQUIRE BUSINESS UNIT ARCHITECT VALIDATION',
  RBDL: 'REJECTED BY DOMAIN LEADER',
  RBCA: 'REJECTED BY COMMUNITY ARCHITECT',
  RBBUA: 'REJECTED BY BUSINESS UNIT ARCHITECT',
  PBUA: 'PENDING BUSINESS UNIT ARCHITECT',
  PDL: 'PENDING DOMAIN LEADER',
  PCA: 'PENDING COMMUNITY ARCHITECT',
  PP: 'PENDING PUBLISHING',
  APPROVED: 'APPROVED',
};

export const nextValidationStatus = {
  [validationStatuses.RDLV]: {
    product: validationStatuses.PDL,
    component: validationStatuses.PDL,
  },
  [validationStatuses.RBUA]: {
    product: validationStatuses.PBUA,
    component: validationStatuses.PBUA,
  },
  [validationStatuses.RBDL]: {
    product: validationStatuses.RDLV,
    component: validationStatuses.RDLV,
  },
  [validationStatuses.RBCA]: {
    product: validationStatuses.PCA,
    component: validationStatuses.PCA,
  },
  [validationStatuses.RBBUA]: {
    product: validationStatuses.PBUA,
    component: validationStatuses.PBUA,
  },
  [validationStatuses.PBUA]: {
    product: validationStatuses.PCA,
    component: validationStatuses.APPROVED,
  },
  [validationStatuses.RCA]: {
    product: validationStatuses.PCA,
    component: validationStatuses.PCA,
  },
  [validationStatuses.PDL]: {
    product: validationStatuses.PCA,
    component: validationStatuses.PCA,
  },
  [validationStatuses.PCA]: {
    product: validationStatuses.APPROVED,
    component: validationStatuses.APPROVED,
  },
};

export const nextRejectedValidationStatus = {
  [validationStatuses.PDL]: validationStatuses.RBDL,
  [validationStatuses.PCA]: validationStatuses.RBCA,
  [validationStatuses.PBUA]: validationStatuses.RBBUA,
};

export const validationRejectedReasons = {
  NA: 'N/A',
  OTHER: 'OTHER',
  ID: 'INCORRECT DOMAIN',
};
