import { pick } from 'lodash';
import { createSelector } from 'reselect';
import moment from 'moment';

import { DEFAULT_LANGUAGE } from 'config/languages';
import { localeSorterInObject } from 'services/utils';

const sorterRole = localeSorterInObject('label');

const sorterProduct = localeSorterInObject('name');

export const selectCurrentUser = state => state.users.current;

export const selectUsersMap = state => state.users.map;

export const selectExternalUsersMap = state => state.users.externalUsersMap;

export const selectUserIdsLoadingMap = state => state.users.idsLoading;

export const selectUserExternalIdsLoadingMap = state => state.users.externalIdsLoading;

export const selectRolesOnTopMap = state => state.users.rolesMap;

export const selectCurrentUserCreatedProductMap = state => state.users.myCreatedProductsMap;

export const selectProductsToValidateMap = state => state.users.productsToValidateMap;

export const selectCurrentUserFollowedProductsMap = state => state.users.myFollowedProductsMap;

export const selectUsersIdsList = createSelector(
  [
    selectUsersMap,
  ],
  Object.keys,
);

export const selectExternalUsersIdsList = createSelector(
  [
    selectExternalUsersMap,
  ],
  Object.keys,
);

export const selectUserIdsLoadingList = createSelector(
  [
    selectUserIdsLoadingMap,
  ],
  idsLoadingMap => Object.keys(idsLoadingMap),
);

export const selectUserExternalIdsLoadingList = createSelector(
  [
    selectUserExternalIdsLoadingMap,
  ],
  idsLoadingMap => Object.keys(idsLoadingMap),
);

export const selectUsersByExternalIds = (state, ids) => pick(selectExternalUsersMap(state), ids);

export const selectUserByExternalId = (state, id) => selectExternalUsersMap(state)[id];

export const selectUserById = (state, userId) => selectUsersMap(state)[userId];

export const selectIsCurrentUserLoading = state => !!state.users.isLoading.getCurrentUser;

export const selectAreUsersLoading = state => !!state.users.isLoading.getUsers;

export const selectUserPreferences = state => selectCurrentUser(state)?.preferences;

export const selectUserSearchCachedFilters = state => selectCurrentUser(state)?.cachedSearchFilters;

export const selectUserPreferencesLoading = state => !!state.users.isLoading.preferences;

export const selectUserWorkplaceIdLoading
  = (state, userId) => !!state.users.isLoading.getUserWorkplaceId[userId];

export const selectUserLanguage = state => selectUserPreferences(state)?.language
  || DEFAULT_LANGUAGE;

export const isLoadingUsers = createSelector(
  [
    selectIsCurrentUserLoading,
    selectAreUsersLoading,
    selectUserPreferencesLoading,
  ],
  (...args) => args.some(v => v),
);

export const selectRolesOnTopList = createSelector(
  [
    selectRolesOnTopMap,
  ],
  r => Object.values(r).sort(sorterRole),
);

export const selectCurrentUserCreatedProductList = createSelector(
  [
    selectCurrentUserCreatedProductMap,
  ],
  productsMap => Object.values(productsMap).sort((a, b) => (
    moment(a.created).isBefore(b.created) ?
      1
      : -1
  )),
);

export const selectProductsToValidateList = createSelector(
  [
    selectProductsToValidateMap,
  ],
  productsMap => Object.values(productsMap).sort((a, b) => (
    moment(a.created).isBefore(b.created) ? 1 : -1
  )),
);

export const selectCurrentUserTotalCreatedProductCount
  = state => state.users.totalCreatedProductCount;

export const selectTotalProductsToValidateCount
  = state => state.users.totalProductsToValidateCount;

export const selectCurrentUserFollowedProductList = createSelector(
  [
    selectCurrentUserFollowedProductsMap,
  ],
  p => Object.values(p).sort(sorterProduct),
);

export const selectFollowedProductById
  = (state, id) => selectCurrentUserFollowedProductsMap(state)[id];

export const selectIsProductFollowed
  = (state, id) => !!selectFollowedProductById(state, id)?.followed;
