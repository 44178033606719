import agent from 'services/http';
import * as UploadService from 'services/upload';
import { BUSINESS_OBJECTS, PRODUCT_FIELDS } from 'config/constants';
import { mapProductFromServer, mapProductsFromServer } from './products.mapper';

const e = encodeURIComponent;

/**
 * GET /api/products.
 * Get the list of products.
 *
 * @async
 * @param {object} query - Query parameters.
 * @param {boolean} setInCache - Is store the value in the Redis cache.
 *
 * @returns {Array<object>} List of product.
 */
export const getProducts = async (query = {}, setInCache) => {
  const isLDAP = val => /^\d+$/.test(val) && val.length === 8;
  if (!query.externalId && !isLDAP(query.q)) {
    const { body: { products, count } }
      = await agent.post('/api/products/search')
        .query({ setInCache }).send(query);
    return {
      count,
      products: mapProductsFromServer(products),
    };
  }
  if (!query.externalId) {
    query.externalId = query.q;
  }
  const { body: { products, count } }
      = await agent.post(`/api/products/searchByExternalId/${query.externalId}`)
        .query({ setInCache }).send(query);
  return {
    count,
    products: mapProductsFromServer(products),
  };
};

export const getProductsCount = async (query = {}) => {
  const { body: { count } }
    = await agent.post('/api/products/count').send(query);
  return {
    count,
  };
};

/**
 * GET /api/product/:productId.
 * Get a product.
 *
 * @async
 * @param {string} productId - Product id.
 * @param {object} [query] - Additional parameters.
 * @param {string} [query.lang] - Translate the product to this language.
 * @returns {object} Product.
 */
export const getProduct = async (productId, { lang } = {}) => {
  const { body } = await agent
    .get(`/api/products/${e(productId)}`)
    .query({ lang });

  return mapProductFromServer(body);
};

/**
 * POST /api/uploads.
 * Post a screenshot of the product.
 *
 * @param {object} file - File to upload.
 * @param {Function} onProgressChange - Callback called when upload progress changes.
 * @returns {object} - Id and url of the created file.
 */
export const postProductScreenshots = (file, onProgressChange) => UploadService
  .postFile(BUSINESS_OBJECTS.PRODUCTS, PRODUCT_FIELDS.SCREENSHOTS, file, onProgressChange);

/**
 * POST /api/uploads.
 * Post the logo of product.
 *
 * @param {object} file - File to upload.
 * @param {Function} onProgressChange - Callback called when upload progress changes.
 * @returns {object} - Id and url of the created file.
 */
export const postProductLogo = (file, onProgressChange) => UploadService
  .postFile(BUSINESS_OBJECTS.PRODUCTS, PRODUCT_FIELDS.LOGO, file, onProgressChange);

/**
 * POST /api/products.
 *
 * @param {object} product - The product (backend format).
 * @param {boolean} suppressWarnings - Suppress warnings.
 * @returns {object} The created product with its id.
 */
export const createProduct = async (product, suppressWarnings) => {
  const { body } = await agent.post('/api/products/')
    .query({
      suppressWarnings,
    })
    .send(product);
  return body;
};

/**
 * PATCH /api/products/:productId.
 *
 * @param {string} productId - The product Id.
 * @param {object} product - The product (backend format).
 * @param {boolean} suppressWarnings - Suppress warnings.
 * @param {boolean} resendValidation - Only resend validation emails.
 * @returns {object} The edited product.
 */
export const editProduct = async (productId, product, suppressWarnings, resendValidation) => {
  const { body } = await agent
    .patch(`/api/products/${e(productId)}`)
    .query({
      suppressWarnings,
      resendValidation,
    })
    .send(product);

  return body;
};

/**
 * DELETE /api/products/:productId.
 *
 * @async
 * @param {string} productId - The product Id.
 * @returns {object} Http response.
 */
export const deleteProduct = productId => agent.delete(`/api/products/${e(productId)}`);

/**
 * GET /api/productTags/:tagType.
 *
 * @param {string} tagType - Tag type.
 * @param {string} query - Starts with query.
 * @returns {Array<object>} - Tags.
 */
export async function getTagsStartingWith(tagType, query) {
  const { body } = await agent.get(`/api/productTags/${e(tagType)}?startsWith=${query}`);
  return body;
}

/**
 * GET /api/productRoles.
 *
 * @param {string} query - Starts with query.
 * @returns {Array<object>} - Roles.
 */
export async function getRolesStartingWith(query) {
  const { body } = await agent.get(`/api/productRoles?startsWith=${query}`);
  return body;
}

/**
 * GET /api/productRoles.
 *
 * @param {string} query - Starts with query.
 * @returns {Array<object>} - Roles.
 */
export async function searchRoles(query) {
  const { body } = await agent.get(`/api/productRoles?q=${query}`);
  return body;
}

/**
 * GET /api/productRoles.
 *
 * @returns {Array<object>} - Roles to display on top.
 */
export async function getRolesOnTop() {
  const { body } = await agent.get('/api/productRoles?displayOnTop=true');
  return body;
}

/**
 * POST /api/productTags/:tagType.
 *
 * @param {string} tagType - Tag type.
 * @param {string} label - Tag label.
 * @returns {object} - Tag.
 */
export async function createTag(tagType, label) {
  const { body } = await agent
    .post(`/api/productTags/${e(tagType)}`)
    .send({ label });
  return body;
}

/**
 * GET /api/softwarePublishers.
 *
 * @param {string} query - Starts with query.
 * @returns {Array<object>} - Software Publishers.
 */
export async function getPublishersStartingWith(query) {
  const { body } = await agent.get(`/api/softwarePublishers?startsWith=${query}&displayOnTop=false`);
  return body;
}

/**
 * POST /api/softwarePublishers.
 *
 * @param {string} label - Label of the new Software Publisher.
 * @returns {object} - Software Publisher created.
 */
export async function createPublisher(label) {
  const { body } = await agent
    .post('/api/softwarePublishers')
    .send({ label });
  return body;
}
