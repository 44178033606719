import { keyBy } from 'lodash';
import { formatRoute } from 'react-router-named-routes';

import ROUTES from 'react/routes';

import {
  PRODUCT_TABS_MESSAGES,
  NAVIGATION_SHORTCUTS_MESSAGES,
  NAVIGATION_SHORTCUTS_COUNT_MESSAGES,
  PREFERENCES_TABS_MESSAGES,
  SETTINGS_TABS_MESSAGES,
} from './constants.messages';

export const MAX_INT32 = 2147483647;

export const BUSINESS_OBJECTS = {
  BUSINESS_UNITS: 'BUSINESS_UNITS',
  COMMUNITIES: 'COMMUNITIES',
  DOMAINS: 'DOMAINS',
  PRODUCTS: 'PRODUCTS',
  REVIEWS: 'REVIEWS',
  REPLIES: 'REPLIES',
  ATOMIC_ACTIVITIES: 'ATOMIC_ACTIVITIES',
  ARCHITECTURE_BUILDING_BLOCKS: 'ARCHITECTURE_BUILDING_BLOCKS',
  SUBSETS: 'SUBSETS',
  PERSONS: 'PERSONS',
};

export const PRODUCT_FIELDS = {
  LOGO: 'logo',
  SCREENSHOTS: 'screenshots',
};

export const DEBOUNCE_TIME = 500;

// Default buffering time for buffered action.
export const BUFFERED_ACTION_TIMEOUT = 100;

// Duration of banner when following a product.
export const FOLLOW_PRODUCT_BANNER_TIMEOUT = 3000;

// Best average for a project is 5 / 5.
export const MAX_MARK = 5;

// Best maturity for a project is 10 / 10.
export const MAX_MATURITY = 10;

// Paginated items default page size.
export const PAGE_SIZE = 24;

// Define the maximum size for the uploaded file (in bytes).
export const MAX_SIZE_FILE_UPLOAD = 1048576;

// Define the maximum size for the logo file (in bytes).
export const MAX_SIZE_LOGO_UPLOAD = 6291456;

// Define the maximum size for the screeshot file (in bytes).
export const MAX_SIZE_SCREENSHOT_UPLOAD = 10485760;

// Define the MIME types authorize for the upload.
export const ACCEPT_MIME_TYPES = 'image/jpeg, image/png';

export const ENABLE_MULTIPLE_UPLOAD = false;

// Max length of product fields.
export const MAX_SHORT_DESCRIPTION_LENGTH = 255;
export const MAX_PRODUCT_NAME_LENGTH = 255;
export const MAX_FEATURE_LENGTH = 255;
export const MAX_LINK_LENGTH = 2048;
export const MAX_UPLOAD_ALT_TEXT_LENGTH = 255;
export const MAX_MODULE_TITLE_LENGTH = 255;
export const MAX_VERSION_TITLE_LENGTH = 255;
export const MAX_LINK_TITLE_LENGTH = 100;
export const MAX_DEPLOYMENT_NICKNAME_LENGTH = 255;
export const MAX_GITHUB_PREFIX_LENGTH = 255;
export const MAX_SERVICE_LEVELS_INFO_LENGTH = 255;
export const MAX_REJECTED_REASON_DESCRIPTION_LENGTH = 255;

export const LATEST_REVIEWS_DISPLAY_COUNT = 3;

export const PRODUCT_VIEW = {
  OVERVIEW: 'overview',
  VERSIONS: 'versions',
  REVIEWS: 'reviews',
  SUGGESTIONS: 'suggestions',
  BUSINESS_VALUES: 'business_values',
  ADDITIONAL_INFORMATION: 'additional_information',
};

export const PRODUCT_TABS = [
  {
    id: 'overview',
    label: PRODUCT_TABS_MESSAGES.PRODUCT_TABS_OVERVIEW,
    href: formatRoute(ROUTES.PRODUCT.PRESENTATION, { view: PRODUCT_VIEW.OVERVIEW }),
  },
  {
    id: 'versions',
    label: PRODUCT_TABS_MESSAGES.PRODUCT_TABS_VERSIONS,
    href: formatRoute(ROUTES.PRODUCT.PRESENTATION, { view: PRODUCT_VIEW.VERSIONS }),
  },
  {
    id: 'reviews',
    label: PRODUCT_TABS_MESSAGES.PRODUCT_TABS_REVIEWS,
    href: formatRoute(ROUTES.PRODUCT.PRESENTATION, { view: PRODUCT_VIEW.REVIEWS }),
  },
  {
    id: 'suggestions',
    label: PRODUCT_TABS_MESSAGES.PRODUCT_TABS_SUGGESTIONS,
    href: formatRoute(ROUTES.PRODUCT.PRESENTATION, { view: PRODUCT_VIEW.SUGGESTIONS }),
  },
  {
    id: 'business_values',
    label: PRODUCT_TABS_MESSAGES.PRODUCT_TABS_BUSINESS_VALUES,
    href: formatRoute(ROUTES.PRODUCT.PRESENTATION, { view: PRODUCT_VIEW.BUSINESS_VALUES }),
  },
  {
    id: 'additional_information',
    label: PRODUCT_TABS_MESSAGES.PRODUCT_TABS_ADDITIONAL_INFORMATION,
    href: formatRoute(ROUTES.PRODUCT.PRESENTATION, { view: PRODUCT_VIEW.ADDITIONAL_INFORMATION }),
  },
];

export const PRODUCT_TABS_MAP = keyBy(PRODUCT_TABS, 'id');

export const NAVIGATION_SHORTCUTS = [
  {
    value: 'allProducts',
    label: NAVIGATION_SHORTCUTS_MESSAGES.ALL_PRODUCTS,
    countMessage: NAVIGATION_SHORTCUTS_COUNT_MESSAGES.ALL_PRODUCTS,
    link: formatRoute(ROUTES.PRODUCTS),
  },
  {
    value: 'communities',
    label: NAVIGATION_SHORTCUTS_MESSAGES.COMMUNITIES,
    countMessage: NAVIGATION_SHORTCUTS_COUNT_MESSAGES.COMMUNITIES,
    link: formatRoute(ROUTES.COMMUNITIES),
  },
  // {
  //   value: 'businessActivities',
  //   label: NAVIGATION_SHORTCUTS_MESSAGES.BUSINESS_ACTIVITIES,
  //   countMessage: NAVIGATION_SHORTCUTS_COUNT_MESSAGES.BUSINESS_ACTIVITIES,
  //   link: '/businessActivities',
  // },
  {
    value: 'businessUnits',
    label: NAVIGATION_SHORTCUTS_MESSAGES.BUSINESS_UNITS,
    countMessage: NAVIGATION_SHORTCUTS_COUNT_MESSAGES.BUSINESS_UNITS,
    link: formatRoute(ROUTES.BUSINESS_UNITS),
  },
];

export const DISPLAY_MODES = {
  GRID: 'grid',
  GRID_COMPACT: 'grid_compact',
  LIST: 'list',
};

export const VOTES = {
  UPVOTE: 1,
  REMOVE: 0,
  DOWNVOTE: -1,
};

export const HTTP_STATUS_CODES = {
  UNAUTHORIZED: 401,
};

export const PREFERENCES_VIEW = {
  CREATED: 'created',
  VALIDATE: 'validate',
  FOLLOWED: 'followed',
  NOTIFICATIONS: 'notifications',
  HISTORY: 'history',
};

export const PREFERENCES_TABS = [
  {
    id: 'created',
    label: PREFERENCES_TABS_MESSAGES.PREFERENCES_TABS_CREATED,
    href: formatRoute(ROUTES.PREFERENCES, { view: PREFERENCES_VIEW.CREATED }),
  },
  {
    id: 'validate',
    label: PREFERENCES_TABS_MESSAGES.PREFERENCES_TABS_VALIDATE,
    href: formatRoute(ROUTES.PREFERENCES, { view: PREFERENCES_VIEW.VALIDATE }),
  },
  {
    id: 'followed',
    label: PREFERENCES_TABS_MESSAGES.PREFERENCES_TABS_FOLLOWED,
    href: formatRoute(ROUTES.PREFERENCES, { view: PREFERENCES_VIEW.FOLLOWED }),
  },
  {
    id: 'notifications',
    label: PREFERENCES_TABS_MESSAGES.PREFERENCES_TABS_NOTIFICATIONS,
    href: formatRoute(ROUTES.PREFERENCES, { view: PREFERENCES_VIEW.NOTIFICATIONS }),
  },
  {
    id: 'history',
    label: PREFERENCES_TABS_MESSAGES.PREFERENCES_TABS_HISTORY,
    href: formatRoute(ROUTES.PREFERENCES, { view: PREFERENCES_VIEW.HISTORY }),
  },
];

export const PREFERENCES_TABS_MAP = keyBy(PREFERENCES_TABS, 'id');

export const SETTINGS_VIEW = {
  LANGUAGE: 'language',
  NOTIFICATIONS: 'notifications',
};

export const SETTINGS_TABS = [
  {
    id: 'language',
    label: SETTINGS_TABS_MESSAGES.SETTINGS_TABS_LANGUAGE,
    href: formatRoute(ROUTES.SETTINGS, { view: SETTINGS_VIEW.LANGUAGE }),
  },
  {
    id: 'notifications',
    label: SETTINGS_TABS_MESSAGES.SETTINGS_TABS_NOTIFICATIONS,
    href: formatRoute(ROUTES.SETTINGS, { view: SETTINGS_VIEW.NOTIFICATIONS }),
  },
];

export const SETTINGS_TABS_MAP = keyBy(SETTINGS_TABS, 'id');

export const TAG_TYPES = {
  MISC: 'misc',
  OS: 'os',
  TECHNO: 'techno',
  DEVICES: 'devices',
};

export const MIN_TEAM_MEMBERS_LENGTH = 2;
export const MIN_FEATURES_LENGTH = 3;
export const MIN_BUSINESS_UNITS_LENGTH = 1;
export const MIN_SCREENSHOTS_LENGTH = 3;
export const MIN_MODULES_LENGTH = 1;
export const MIN_LINKS_LENGTH = 1;

export const PRODUCT_SORTING = {
  NAME_ASC: 'name',
  NAME_DESC: '-name',
  CREATED_DATE_ASC: 'createdDate',
  CREATED_DATE_DESC: '-createdDate',
  UPDATED_DATE_ASC: 'updatedDate',
  UPDATED_DATE_DESC: '-updatedDate',
};

export const HTTP_BAD_REQUEST_STATUS = 400;

export const HTTP_UNPROCESSABLE_ENTITY = 422;

export const HTTP_SERVICE_UNAVAILABLE_STATUS = 503;

export const PRODUCT_HISTORY_SIZE = 10;

export const LATEST_PRODUCTS_ITEMS = 9;

export const NOTIFICATION_TYPES = {
  NEW_REVIEW_ON_FOLLOWED_PRODUCT: 'NEW_REVIEW_ON_FOLLOWED_PRODUCT',
  NEW_REPLY_ON_REVIEW: 'NEW_REPLY_ON_REVIEW',
  NEW_EDIT_ON_PRODUCT: 'NEW_EDIT_ON_PRODUCT',
  NEW_SUGGESTION_ON_PRODUCT: 'NEW_SUGGESTION_ON_PRODUCT',
  NEW_REPLY_ON_SUGGESTION: 'NEW_REPLY_ON_SUGGESTION',
  NEW_PRODUCT_IN_COMMUNITY: 'NEW_PRODUCT_IN_COMMUNITY',
  NEW_PRODUCT_IN_DOMAIN: 'NEW_PRODUCT_IN_DOMAIN',
  ALL: 'ALL',
};

export const NOTIFICATION_FILTERS = {
  REVIEWS: [
    NOTIFICATION_TYPES.NEW_REVIEW_ON_FOLLOWED_PRODUCT,
    NOTIFICATION_TYPES.NEW_REPLY_ON_REVIEW,
  ],
  SUGGESTIONS: [
    NOTIFICATION_TYPES.NEW_SUGGESTION_ON_PRODUCT,
    NOTIFICATION_TYPES.NEW_REPLY_ON_SUGGESTION,
  ],
  UPDATES: [
    NOTIFICATION_TYPES.NEW_EDIT_ON_PRODUCT,
    NOTIFICATION_TYPES.NEW_PRODUCT_IN_COMMUNITY,
    NOTIFICATION_TYPES.NEW_PRODUCT_IN_DOMAIN,
  ],
  ALL: [
    NOTIFICATION_TYPES.ALL,
  ],
};

export const PRODUCT_BUSINESS_UNITS_DISPLAY_COUNT = 6;

export const DEPLOYMENT_BUSINESS_UNITS_DISPLAY_COUNT = 3;

export const PRODUCT_STAGES = {
  INI: 'INI',
  TBD: 'TBD',
  WIP: 'WIP',
  LIVE: 'LIVE',
  DEC: 'DEC',
  DEP: 'DEP',
  ARCH: 'ARCH',
};

export const PRODUCT_LINKS = {
  URL: 'productUrl',
  MARKETING: 'productMarketingUrl',
  ROADMAP: 'roadmap',
  DOCUMENTATION: 'documentation',
  DISCUSSION: 'discussion',
  API_REFERENCE: 'apiReference',
  SOURCE: 'source',
  BACKLOG: 'backlog',
  SUPPORT: 'support',
  DATASETS: 'datasets',
};

export const PRODUCT_HOSTING_TYPES = {
  OPA: 'OPA',
  OPB: 'OPB',
  PRC: 'PRC',
  PUC: 'PUC',
  SAAS: 'SAAS',
};

export const NOTIFICATIONS_MAILING_FREQUENCY_OPTIONS = {
  NEVER: 'NEVER',
  DAILY: 'DAILY',
  WEEKLY: 'WEEKLY',
  MONTHLY: 'MONTHLY',
};

export const PRODUCT_FIELDS_OPTIONS_CIA = [
  { label: 'N/A', value: null },
  { label: '0', value: '0' },
  { label: '1', value: '1' },
  { label: '2', value: '2' },
  { label: '3', value: '3' },
  { label: '4', value: '4' },
];

export const PRODUCT_FIELDS_OPTIONS_RTO_RPO = [
  { label: 'N/A', value: null },
  { label: '1h', value: 1 },
  { label: '2h', value: 2 },
  { label: '4h', value: 4 },
  { label: '8h  ', value: 8 },
  { label: '12h', value: 12 },
  { label: '24h', value: 24 },
  { label: '48h', value: 48 },
  { label: '72h', value: 72 },
  { label: '168h', value: 168 },
];

export const PRODUCT_ADDITIONAL_INFO_FIELDS_OPTIONS_SLA = [
  { label: '', value: null },
  { label: '5a4', value: '5a4' },
  { label: '7c2', value: '7c2' },
];

export const PRODUCT_ADDITIONAL_INFO_FIELDS_OPTIONS_PERSONAL_DATA = [
  { label: 'N/A', value: 'N/A' },
  { label: 'true', value: 'TRUE' },
  { label: 'false', value: 'FALSE' },
];

export const PRODUCT_ADDITIONAL_INFO_FIELDS_OPTIONS_PSI = [
  { label: 'N/A', value: 'N/A' },
  { label: 'true', value: 'TRUE' },
  { label: 'false', value: 'FALSE' },
];

export const PRODUCT_ADDITIONAL_INFO_FIELDS_OPTIONS_INVOICING_MODEL = [
  { id: '', value: null },
  { id: 'PRODUCT_ADDITIONAL_INFO_INVOICING_MODEL_PPU', value: 'PPU' },
  { id: 'PRODUCT_ADDITIONAL_INFO_INVOICING_MODEL_OTH', value: 'OTH' },
];

export const PRODUCT_BUSINESS_VALUES_INFO_FIELDS_OPTIONS_STAKEHOLDERS = {
  CLI: 'PRODUCT_BUSINESS_VALUES_FORM_STAKEHOLDERS_CLI',
  PAR: 'PRODUCT_BUSINESS_VALUES_FORM_STAKEHOLDERS_PAR',
  SHA: 'PRODUCT_BUSINESS_VALUES_FORM_STAKEHOLDERS_SHA',
  EMP: 'PRODUCT_BUSINESS_VALUES_FORM_STAKEHOLDERS_EMP',
};

export const PRODUCT_VERSION_STATUS_OPTIONS = [
  { label: 'Live', value: 'LIVE' },
  { label: 'To be developed', value: 'TBD' },
  { label: 'Work in progress', value: 'WIP' },
  { label: 'Deprecated', value: 'DEC' },
  { label: 'Decommissioned', value: 'DEP' },
  { label: 'Archived', value: 'ARCH' },
];

export const PRODUCT_VERSION_STATUS_MAP = {
  LIVE: 'Live',
  TBD: 'To be developed',
  WIP: 'Work in progress',
  DEC: 'Deprecated',
  DEP: 'Decommissioned',
  ARCH: 'Archived',
};

// Prefix of redux-form product forms.
export const PRODUCT_FORM_PREFIX = 'forms/productForm/#';

// Draft autosave debounce time in product form.
export const DRAFT_AUTOSAVE_DEBOUNCE_TIME = 2000;

// URL to contact website
export const WORKPLACE_CHAT_URI = 'https://adeo.workplace.com/chat/t';

export const NOTIFICATION_PAGINATION_ITEM_NUMBER = 10;

export const REVIEWS_PAGINATION_ITEM_NUMBER = 10;

export const REVIEWS_PAGINATION_ITEM_NUMBER_OPTIONS = [10, 20, 30, 50];

export const SUGGESTIONS_PAGINATION_ITEM_NUMBER = 10;

export const SUGGESTIONS_PAGINATION_ITEM_NUMBER_OPTIONS = [10, 20, 30, 50];

export const PRODUCT_FIELDS_SUPPORT_DAYS_AVAILABLE = [
  { label: 'Monday', value: 'Monday' },
  { label: 'Tuesday', value: 'Tuesday' },
  { label: 'Wednesday', value: 'Wednesday' },
  { label: 'Thursday', value: 'Thursday' },
  { label: 'Friday', value: 'Friday' },
  { label: 'Saturday', value: 'Saturday' },
  { label: 'Sunday', value: 'Sunday' },
];
