export default {
  HOME: '/home',
  ABOUT: '/about',
  PRODUCTS: '/products',
  PRODUCT: {
    CREATE_FORM: {
      TUTO: '/products/create/tuto',
      FORM: '/products/create/:step',
      CHOOSE: '/products/create/choose',
    },
    EDIT_FORM: '/products/edit/:productId/:step',
    PRESENTATION_DEFAULT: '/products/:productId',
    PRESENTATION: '/products/:productId/:view',
    FEEDBACK_STANDALONE: '/products/:productId/feedback/standalone',
    PRESENTATION_SINGLE: '/products/:productId/:view/:messageId',
    REVIEWS: '/products/:productId/reviews',
    BYUSER: '/products/user/:externalId',
  },
  ARCHITECTURE_BUILDING_BLOCKS: '/abbs',
  BUSINESS_UNITS: '/businessUnits',
  BUSINESS_UNIT: '/businessUnit/:businessUnitId',
  COMMUNITIES: '/communities',
  PREFERENCES: '/preferences/:view',
  SNIPPET_CONFIGURATION: '/preferences/snippet/:productId',
  SETTINGS: '/settings/:view',
  SUBSETS: '/subsets',
  BUSINESS_ACTIVITIES: '/businessActivities',
  NOTIFICATIONS: '/notifications',
  ERRORS: '/error/:code',

};
