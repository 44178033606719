import { keyBy, omit } from 'lodash';
import { combineReducers } from 'redux';
import { ACTIONS } from './constants';

export default combineReducers({
  map: (state = {}, action) => {
    switch (action.type) {
      case ACTIONS.GET_PRODUCT_VERSIONS_SUCCESS:
        return {
          ...state,
          [action.productId]: keyBy(action.versions, 'id'),
        };
      case ACTIONS.POST_PRODUCT_VERSION_SUCCESS:
      case ACTIONS.PATCH_PRODUCT_VERSION_SUCCESS:
        return {
          ...state,
          [action.productId]: {
            ...state[action.productId],
            [action.version.id]: action.version,
          },
        };
      case ACTIONS.DELETE_PRODUCT_VERSION_SUCCESS:
        return {
          ...state,
          [action.productId]: omit(state[action.productId], [action.versionId]),
        };
      default:
        return state;
    }
  },

  isLoading: combineReducers({
    getVersions: (state = 0, action) => {
      switch (action.type) {
        case ACTIONS.GET_PRODUCT_VERSIONS:
          return state + 1;
        case ACTIONS.GET_PRODUCT_VERSIONS_SUCCESS:
        case ACTIONS.GET_PRODUCT_VERSIONS_FAILURE:
          return state - 1;
        default:
          return state;
      }
    },

    postVersion: (state = 0, action) => {
      switch (action.type) {
        case ACTIONS.POST_PRODUCT_VERSION:
          return state + 1;
        case ACTIONS.POST_PRODUCT_VERSION_SUCCESS:
        case ACTIONS.POST_PRODUCT_VERSION_FAILURE:
          return state - 1;
        default:
          return state;
      }
    },

    patchVersion: (state = 0, action) => {
      switch (action.type) {
        case ACTIONS.PATCH_PRODUCT_VERSION:
          return state + 1;
        case ACTIONS.PATCH_PRODUCT_VERSION_SUCCESS:
        case ACTIONS.PATCH_PRODUCT_VERSION_FAILURE:
          return state - 1;
        default:
          return state;
      }
    },

    deleteVersion: (state = 0, action) => {
      switch (action.type) {
        case ACTIONS.DELETE_PRODUCT_VERSION:
          return state + 1;
        case ACTIONS.DELETE_PRODUCT_VERSION_SUCCESS:
        case ACTIONS.DELETE_PRODUCT_VERSION_FAILURE:
          return state - 1;
        default:
          return state;
      }
    },
  }),
});
